import { Icon } from "@virtualcapital/utogi-ui-library";
import { useStyletron } from "baseui";
import { ANCHOR, Drawer } from "baseui/drawer";
import React from "react";

import AppLink, { Modules } from "./AppLink";

type Props = {
  isOpen: boolean;
  setIsOpen: Function;
};

const AppMenu: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [css] = useStyletron();

  return (
    <Drawer
      isOpen={isOpen}
      autoFocus
      onClose={() => setIsOpen(false)}
      anchor={ANCHOR.left}
      overrides={{
        DrawerContainer: {
          style: {
            width: "400px",
            maxWidth: "100%",
          },
        },
        DrawerBody: {
          style: {
            marginRight: "20px",
            marginLeft: "20px",
          },
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          fontSize: "15px",
          alignItems: "center",
          color: "#00aeef",
          padding: "0 15px 10px",
        })}
      >
        <Icon icon="app" />
        <div
          className={css({ flex: 1, color: "#707070", textAlign: "center" })}
        >
          Select App
        </div>
      </div>
      <div>
        <AppLink title="Reporting" icon="reporting" link={Modules.DASHBOARD} />
        <AppLink title="Maps" icon="maps" link={Modules.MAPS} />
        <AppLink title="Email" icon="email" link={Modules.EMAIL} />
        <AppLink title="Contacts" icon="contacts" link={Modules.CONTACTS} />
        <AppLink title="Marketing" icon="marketing" link={Modules.MARKETING} />
        <AppLink
          title="Target Audience"
          icon="target-audience"
          link={Modules.TARGET_AUDIENCE}
        />
        <AppLink
          title="Open Homes"
          icon="open-homes"
          link={Modules.OPEN_HOMES}
        />
        <AppLink title="Flow" icon="flow" link="https://www.zaracrm.com/" />
        <AppLink
          title="Accounting"
          icon="accounting"
          link={Modules.ACCOUNTING}
        />
      </div>
    </Drawer>
  );
};

export default AppMenu;
